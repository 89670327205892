exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-design-system-templates-author-tsx": () => import("./../../../src/components/design-system/templates/author.tsx" /* webpackChunkName: "component---src-components-design-system-templates-author-tsx" */),
  "component---src-components-design-system-templates-blog-post-tsx": () => import("./../../../src/components/design-system/templates/blog-post.tsx" /* webpackChunkName: "component---src-components-design-system-templates-blog-post-tsx" */),
  "component---src-components-design-system-templates-index-tsx": () => import("./../../../src/components/design-system/templates/index.tsx" /* webpackChunkName: "component---src-components-design-system-templates-index-tsx" */),
  "component---src-components-design-system-templates-tag-tsx": () => import("./../../../src/components/design-system/templates/tag.tsx" /* webpackChunkName: "component---src-components-design-system-templates-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-technology-radar-tsx": () => import("./../../../src/pages/technology-radar.tsx" /* webpackChunkName: "component---src-pages-technology-radar-tsx" */)
}

